var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h2',{staticClass:"mb-4"},[_vm._v("Список подборок")]),_c('data-table',{ref:"dataTable",attrs:{"url":"compilations","headers":_vm.headers},scopedSlots:_vm._u([{key:"item.url",fn:function(ref){
var compilation = ref.item;
return [_c('a',{attrs:{"href":_vm.createFullLink(compilation.url),"target":"_blank"}},[_vm._v(_vm._s(compilation.url))])]}},{key:"item.isIndexable",fn:function(ref){
var compilation = ref.item;
return [_vm._v(_vm._s(_vm._f("yesNo")(compilation.seo.isIndexable)))]}},{key:"item.isPublished",fn:function(ref){
var compilation = ref.item;
return [_vm._v(_vm._s(_vm._f("yesNo")(compilation.isPublished)))]}},{key:"item.actions",fn:function(ref){
var compilation = ref.item;
return [_c('nobr',[_c('v-btn',{staticClass:"mr-2",attrs:{"icon":"","color":"accent","to":{ name: 'compilations-id', params: { id: compilation.id } }}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-pencil")])],1),_c('v-btn',{attrs:{"icon":"","color":"error"},on:{"click":function($event){return _vm.deleteCompilation(compilation)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-delete")])],1)],1)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }